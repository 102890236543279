import axios from "axios";

export const baseUrl = process.env.REACT_APP_BASE_URL || "https://admin.modernhouseofantiques.com/o/oauth2/token";
export const client_id = process.env.REACT_APP_CLIENT_ID || "id-3c8d609c-66a4-16ff-73e5-4ad16aa8c030";
export const secret = process.env.REACT_APP_SECRET || "secret-22b1a367-c24f-3eca-ce99-2f8b7f3a43b5";

export const appUrl = process.env.REACT_APP_APPURL || "https://admin.modernhouseofantiques.com/";
export const baseImageUrl = process.env.REACT_APP_BASEIMGURL || "https://admin.modernhouseofantiques.com/";
export const channelId = process.env.REACT_APP_CHANNELID || 42326;
export const groupId =    process.env.REACT_APP_GROUPID || 20119;
export const companyId =  process.env.REACT_APP_COMPANYID || 20096;
export const vocabId  = process.env.REACT_APP_VOCABID || 42561;
export const sites =  process.env.REACT_APP_SITES || 20119;

export const middlewareBaseUrl =  process.env.REACT_APP_MIDDLEWAREBASEURL || "https://admin.modernhouseofantiques.com/o/mha-headless/";
export const apiBaseUrl = process.env.REACT_APP_APIBASEURL || `https://admin.modernhouseofantiques.com/o/headless-commerce-delivery-catalog/v1.0/channels/${channelId}/`;

export const cartBaseUrl = process.env.REACT_APP_CARTBASEURL || `https://admin.modernhouseofantiques.com/o/headless-commerce-delivery-cart/v1.0/channels/${channelId}/`;
  
export const cartUrl = process.env.REACT_APP_CARTURL || `https://admin.modernhouseofantiques.com/o/headless-commerce-delivery-cart/v1.0/channels/carts/`;


const qs = require("qs");

export const requestClientCredentialsAccessToken = () => {
  axios({
    method: "post",
    url: baseUrl,
    data: qs.stringify({
      client_id: client_id,
      client_secret: secret,
      grant_type: "client_credentials",
    }),
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  }).then((res) => {
    localStorage.setItem("token", res.data.access_token);
  });
};


let basicauth = {
  UserName: "admin@giksindia.com",
  Password: "EugxQqFCW7XdfS",
};
export function GetHeaders(val) {
  let headers = {
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
  };
  headers.headers["authorization"] = val
    ? basicauth
    : `Bearer ${localStorage.getItem("token")}`;
  return headers;
}

export function errorCheckHandler(error) {
  let message = "";
  if (error.code === 401) {
  } else {
    if (error.code === 400) {
    }

    if (error.status === 403) {
      message = "You don’t have permission to access on this Server";
    } else if (error.status === 404) {
    } else if (error.status === 502) {
      message = "Bad Gateway error";
    } else if (error.status === 501) {
      message = "server has not  fulfill your request for that content";
    } else if (error.status === 502) {
      message = "Bad Gateway error";
    } else if (error.status === 500) {
      message = "INTERNEL SERVER ERORR";
    } else {
      message = error.code;
    }
  }
  return message;
}
